<div class="main">
  <div class="header">
    <div id="logo" class="">
      <img src="https://seasware.com/jostime/res/logo.svg" alt="">
    </div>
    <div id="contact-us" class="">
      <!--
      <button id="settings" type="button" name="button">
        <i class="fa fa-cog" aria-hidden="true"></i>
      </button>
      -->
      <button id="menu" type="button" (click)="openDownload()" name="button">
        <i class="fas fa-user"></i>
      </button>
    </div>
  </div>
  <div class="categories">

  </div>
  <div class="products">
    <div id="container_apps">

    </div>
  </div>
</div>
<div class="request_module">
  <div class="background">

  </div>
  <div class="request">
    <div class="download" id="by-product">
      <div class="info">
        <div class="">
          <span>¿Te interesa este articulo?</span>
        </div>
        <div class="slogan">
          <span>Te invitamos a descargar gratis nuestra APP</span>
        </div>
      </div>
      <div class="stores">
        <div id="icon">
          <img src="assets/res/icon.png" alt="">
        </div>
        <div id="icon-store">
          <a href="https://play.google.com/store/apps/details?id=com.seasware.jostime">
            <img src="assets/res/googleplay.svg" alt="">
          </a>

          <a href="https://apps.apple.com/cr/app/jostime/id1524183662">
            <img src="assets/res/appstore.svg" alt="">
          </a>

        </div>
      </div>
      <a href="https://seasware.com/jostime">
        <span>Conoce mas sobre nosotros</span>
      </a>
    </div>
    <div class="download" id="by-register">
      <div class="info">
        <div class="">
          <span>¿Te interesa crear una cuenta?</span>
        </div>
        <div class="slogan">
          <span>Te invitamos a descargar gratis nuestra APP</span>
        </div>
      </div>
      <div class="stores">
        <div id="icon">
          <img src="assets/res/icon.png" alt="">
        </div>
        <div id="icon-store">
          <a href="https://play.google.com/store/apps/details?id=com.seasware.jostime">
            <img src="assets/res/googleplay.svg" alt="">
          </a>

          <a href="https://apps.apple.com/cr/app/jostime/id1524183662">
            <img src="assets/res/appstore.svg" alt="">
          </a>

        </div>
      </div>
      <a href="https://seasware.com/jostime">
        <span>Conoce mas sobre nosotros</span>
      </a>
    </div>
  </div>
</div>
