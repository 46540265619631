import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var server;
declare var res_server;
declare var latitude;
declare var longitude;
declare var connectRequest;
declare var calculationByDistance;
declare var near_shop;
declare var test;
let request_module = document.getElementsByClassName("request_module") as HTMLCollectionOf<HTMLElement>;
let background = document.getElementsByClassName("background") as HTMLCollectionOf<HTMLElement>;
let byproduct = document.getElementsByClassName("download") as HTMLCollectionOf<HTMLElement>;

declare var forms;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'JosTime';
  //constructor(public http: HttpClient) { }
  postId;

  constructor(private http: HttpClient) { }
  ngOnInit() {

    this.http.get(server + 'get_categories.php').subscribe(data => {
        var that = this;
        for (let i = 0; i < Object.keys(data).length; i++) {
          const element = data[i].name;
          var button = document.createElement("button");
          let category = data[i].name;
          button.innerHTML = category;

          button.setAttribute('class', 'category_button');
          button.onclick = function () {
            that.selectCategory(category,that)
          }
          var categories_container = document.getElementsByClassName("categories")[0];
          categories_container.appendChild(button);
        }


        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log("Latitude: " + position.coords.latitude +
            "<br>Longitude: " + position.coords.longitude);
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            var position_user = { lat: latitude, lng: longitude }
            that.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude +','+ longitude + '&sensor=false&key=AIzaSyBAxhz5lRFlymcSR7gC6n7HuzXCeNAEl6Y').subscribe(data => {
              console.log(data);
              connectRequest(server + 'get_sellers.php',
                [
                  {
                    header:"country_code",
                    content:"CR"
                  }
                ],
                function (response) {
                  //console.log(response);
                  var position_array_near_shop = 0
                  var sellers = JSON.parse(response);
                  for (let i = 0; i < sellers.length; i++) {
                    var id = sellers[i].seller_id
                    var name = sellers[i].name
                    var latitude = sellers[i].latitude
                    var longitude = sellers[i].longitude
                    var restricted_area = sellers[i].restricted_area
                    var max_distance = sellers[i].max_distance
                    var second = { lat: latitude, lng: longitude }
                    if (restricted_area == 1) {
                          //print("distance " + CalculationByDistance(position,second).toString())
                          //console.log(position_user);
                          //console.log(second);
                          //console.log(CalculationByDistance(second,{lat: 9.908453, lng: -84.064799}));

                          if (max_distance >= calculationByDistance(position_user,second)) {
                              //near_shop = createArrayPHP(near_shop,id,position_array_near_shop)
                              near_shop.push(id);
                              position_array_near_shop++
                          } else {
                              console.log("no aplica: " +  name)
                          }
                          //Toast.makeText(applicationContext,values[0],Toast.LENGTH_SHORT).show()
                    } else if (restricted_area == 0) {
                          //near_shop = createArrayPHP(near_shop,id,position_array_near_shop)
                          near_shop.push(id);
                          position_array_near_shop++
                    }
                  }
                  connectRequest(server + 'get_products_category.php',
                    [
                      {
                        header:"category",
                        content:"all departments"
                      },
                      {
                        header:"array_seller_id",
                        content:JSON.stringify(near_shop)
                      }
                    ],
                    function (response) {
                      //console.log(response);
                      var products = JSON.parse(response);
                      for (var i = 0; i < products.length; i++) {
                        //console.log(products[i]);
                        let name = products[i].name;
                        let image_url = products[i].image;
                        let category = products[i].category;
                        let price = products[i].price;
                        let delivery_time = products[i].delivery_time;
                        let unit_of_time = products[i].unit_of_time
                        let qualification = products[i].qualification;
                        forms.createProductContainer(name,res_server + "products/" + image_url + ".jpg",category,price,delivery_time,unit_of_time,qualification,function () {
                          request_module[0].style.display = "flex";
                          byproduct[0].style.display = "flex";
                          background[0].onclick = function () {
                            request_module[0].style.display = "none";
                            byproduct[0].style.display = "none";
                          }
                        },document.getElementById("container_apps"))
                      }
                    },
                    function (response) {
                      console.log(response);
                    }
                  );
                },
                function (response) {
                  console.log(response);
                }
              );
            })
          });
        } else {
          console.error("Geolocation is not supported by this browser.");
        }

    })

  }
  selectCategory(category,that) {
    connectRequest(server + 'get_products_category.php',
      [
        {
          header:"category",
          content:category
        },
        {
          header:"array_seller_id",
          content:JSON.stringify(near_shop)
        }
      ],
      function (response) {
        console.log(response);
        that.removeAllChildNodes(document.getElementById("container_apps"))
        var products = JSON.parse(response);
        for (var i = 0; i < products.length; i++) {
          //console.log(products[i]);
          let name = products[i].name;
          let image_url = products[i].image;
          let category = products[i].category;
          let price = products[i].price;
          let delivery_time = products[i].delivery_time;
          let unit_of_time = products[i].unit_of_time
          let qualification = products[i].qualification;
          forms.createProductContainer(name,res_server + "products/" + image_url + ".jpg",category,price,delivery_time,unit_of_time,qualification,function () {
            request_module[0].style.display = "flex";
            byproduct[0].style.display = "flex";
            background[0].onclick = function () {
              request_module[0].style.display = "none";
              byproduct[0].style.display = "none";
            }
          },document.getElementById("container_apps"))
        }
      },
      function (response) {
        console.log(response);
      }
    )
  }
  removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
  }

  openDownload(){
    request_module[0].style.display = "flex";
    byproduct[1].style.display = "flex";
    background[0].onclick = function () {
      request_module[0].style.display = "none";
      byproduct[1].style.display = "none";
    }
  }

  /*
  ngOnInit() {
      // Simple GET request with response type <any>
      this.http.get(server + 'get_categories.php').subscribe(data => {
          for (let i = 0; i < data.length; i++) {
            const element = data[i].name;
            var button = document.createElement("button");
            button.innerHTML = data[i].name
            button.setAttribute('class', 'category_button');
            button.setAttribute('onclick', 'test()');
            var categories_container = document.getElementsByClassName("categories")[0];
            categories_container.appendChild(button);
          }

      })
      var that = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log("Latitude: " + position.coords.latitude +
          "<br>Longitude: " + position.coords.longitude);
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          var position_user = { lat: latitude, lng: longitude }
          that.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude +','+ longitude + '&sensor=false&key=AIzaSyBAxhz5lRFlymcSR7gC6n7HuzXCeNAEl6Y').subscribe(data => {
            console.log(data);
            connectRequest(server + 'get_sellers.php',
              [
                {
                  header:"country_code",
                  content:"CR"
                }
              ],
              function (response) {
                console.log(response);
                var position_array_near_shop = 0
                var sellers = JSON.parse(response);
                for (let i = 0; i < sellers.length; i++) {
                  var id = sellers[i].seller_id
                  var name = sellers[i].name
                  var latitude = sellers[i].latitude
                  var longitude = sellers[i].longitude
                  var restricted_area = sellers[i].restricted_area
                  var max_distance = sellers[i].max_distance
                  var second = { lat: latitude, lng: longitude }
                  if (restricted_area == 1) {
                        //print("distance " + CalculationByDistance(position,second).toString())
                        //console.log(position_user);
                        //console.log(second);
                        //console.log(CalculationByDistance(second,{lat: 9.908453, lng: -84.064799}));

                        if (max_distance >= CalculationByDistance(position_user,second)) {
                            //near_shop = createArrayPHP(near_shop,id,position_array_near_shop)
                            near_shop.push(id);
                            position_array_near_shop++
                        } else {
                            console.log("no aplica: " +  name)
                        }
                        //Toast.makeText(applicationContext,values[0],Toast.LENGTH_SHORT).show()
                  } else if (restricted_area == 0) {
                        //near_shop = createArrayPHP(near_shop,id,position_array_near_shop)
                        near_shop.push(id);
                        position_array_near_shop++
                  }
                }
                connectRequest(server + 'get_products_category.php',
                  [
                    {
                      header:"category",
                      content:"all departments"
                    },
                    {
                      header:"array_seller_id",
                      content:JSON.stringify(near_shop)
                    }
                  ],
                  function (response) {
                    console.log(response);
                  },
                  function (response) {
                    console.log(response);
                  }
                );
              },
              function (response) {
                console.log(response);
              }
            );
          })
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }


  }

  getCountry() {
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude +','+ longitude + '&sensor=false&key=AIzaSyBAxhz5lRFlymcSR7gC6n7HuzXCeNAEl6Y').subscribe(data => {
        console.log(data);
    })
  }*/


}
